import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'zh',
    resources: {
      zh: {
        translation: {
          donate: "成为赞助商",
          warning: "如果你是新手，请先阅读下面的问题和答案！",
          options: {
            reset: "重置",
            cancel: "取消",
            inanna: "伊南娜",
            hell: "地狱模式",
            skullSlider: "骷髅上放置炸弹优先级(如不理解默认即可)",
            speech: "语音识别",
            speechWarning: "你的浏览器不支持语音识别。",
          },
          message: {
            specialSkull: "特殊骷髅",
            initialSkull: "初始骷髅",
            bombNumber: "炸弹",
            placementTime: "请放置",
            needBingo: " (bingo!)",
            cannotPlace: "不要放在这里!",
            impossibleInanna: "无法达成bingo! 请放伊南娜或者GG",
            bombSpecialSkull: "特殊骷髅上放置了炸弹...GG",
          },
          description: {
            q1: "Q. 如何使用?",
            a1: "先放置前两个初始骷髅的位置，之后每次放置炸弹时，点击对应位置，就会帮你进行下一步提示。\n" +
            "如果你点错了，可以按 [取消] 按钮返回上一步，或者如果你想重新开始，可以点击 [重置] 按钮。",
            q2: "Q. 我不知道该把炸弹放在那里。",
            a2: "我们为你添加了推荐功能。bingo游戏板上蓝色背景的部分是推荐点。\n" +
            "颜色越深优先级越高",
            q3: "Q. 在建议的地方有骷髅或一摆锤。",
            a3: "我们为你推荐了三个放置点。我们还尽可能确保三个放置点中的一个不包含骷髅。",
            q4: "Q. 我不想越过骷髅去放置炸弹。",
            a4: "对于那些有这种偏好的人，可以通过调整 [骷髅上放置炸弹优先级] 的偏好：向左，会推荐带骷髅的位置放置炸弹；向右，不会推荐带骷髅的位置；如果在最右边的时候，仍然会得到带骷髅的位置，那就是无其他推荐位置了。",
            q5: "Q. 这次你要用伊南娜吗？",
            a5: "对于那些有这种想法的人，我们增加了 [伊南娜] 模式，可以在 [伊南娜] 方框中打勾，我们将不考虑bingo，只考虑放置的难易程度。\n" +
            "不过，我们认为你不会连续两次使用伊南娜，所以我们在 3 局定时器结束后自动取消了伊南娜复选框。",
            q6: "Q. 我没有双显示器。",
            a6: "对于那些（可能）有手机的人，我们设计了手机页面。没有手机？",
            q7: "Q. 我玩的是地狱难度？",
            a7: "我们还为您提供了地狱模式：第一次点击特殊方块位置，下一次点击普通骷髅位置。",
          },
        },
      },
      // en: {
      //   translation: {
      //     donate: "Donate",
      //     warning: "※ First-time users: please read the Q&A below!",
      //     options: {
      //       reset: "reset",
      //       cancel: "undo",
      //       inanna: "Inanna Ready",
      //       hell: "Hell Mode (KR only)",
      //       skullSlider: "Place bombs on skulls?",
      //       speech: "Speech recognition (KR only)",
      //       speechWarning: "Your browser does not support speech recognition.",
      //     },
      //     message: {
      //       specialSkull: "Special (Hell) skull",
      //       initialSkull: "Initial skull",
      //       bombNumber: "-round bomb",
      //       placementTime: "placement",
      //       needBingo: " (Need bingo!)",
      //       cannotPlace: "Cannot place skull there!",
      //       impossibleInanna: "Bingo impossible! Inanna or GG",
      //       bombSpecialSkull: "Bomb on special skull...GG",
      //     },
      //     description: {
      //       q1: "Q. How do I use the tool?",
      //       a1: "Click on the positions of the initial two skulls. After, click on any position to place a bomb. Every time you place a bomb, it will automatically flip adjacent tiles.\n" +
      //       "If you put the bomb in the wrong tile, you can go back with the [UNDO] button, and if you want to reset the board, just click the [RESET] button.",
      //       q2: "Q. Where should I put the bombs?",
      //       a2: "The tool has a recommendation feature. Recommended tiles are marked with blue colors.\n" +
      //       "The recommendation logic prioritizes playing a bingo every 3rd round, followed by keeping the middle-top board clean for easy DPS.\n" +
      //       "Of course, a few formulas won't give the perfect answer for every possible combination, but it's generally usable :)",
      //       q3: "Q. What should I do if there's a skull or hammer in the recommended spot?",
      //       a3: "For flexibility, up to 3 recommendations are provided. The more recommended positions are displayed in dark blue, but the lighter blue spots are fine too. Also, whenever possible, we try to ensure that at least one of the three positions contains no skull.",
      //       q4: "Q. What if I don't want to stand on skulls to put the bombs?",
      //       a4: "You can adjust your preferences for bombs on skulls using the slider. The left side would not consider skulls at all, while the right side would avoid skulls as much as possible. If you put the slider the right side and still skull tiles are recommended, it means that you should really go for it.",
      //       q5: "Q. What if I plan to use Inanna?",
      //       a5: "There is an Inanna checkbox. If it is checked, the tool WILL NOT TRY TO MAKE A BINGO for the upcoming wipe and instead optimize the board for easy DPS.\n" +
      //       "However, since there is no situation where you can use Inanna twice in a row, the Inanna checkbox will become unchecked after using it to pass a wipe with no bingo.",
      //       q6: "Q. I don't have two monitors, can I still use this tool?",
      //       a6: "I designed the tool to be easy to view and use on mobile. DO YOU GUYS NOT HAVE PHONES?",
      //       q7: "Q. How can I use the tool if I'm busy avoiding patterns?",
      //       a7: "There is a voice recognition mode. However, it's not available for EN.",
      //       q8: "Q. What if I'm playing hell mode?",
      //       a8: "There is a hell mode checkbox. The first click on the grid will place the special skull tile, then the next click will place a normal skull tile.",
      //     },
      //   },
      // }
    }
  });

export default i18n;
